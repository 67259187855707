<template>
<div class="sign-outer">
  <div class="sign center">
    <BTitle :text="$route.params.type == 2? '展商注册':'观众报名'"></BTitle>
    <div class="flex" style="min-height: 45vh;">
      <img src="@/assets/wxcode.jpg" style="width: 500px;">
    </div>
    <!-- <div class="form-wrap">
      <Form v-if="reset" ref="signForm" :label-width="100" :model="addInfo" :rules="ruleValidate">
        <Row>
          <Col span="12">
            <FormItem label="报名选项" prop="signType">
              <RadioGroup class="flex" v-model="addInfo.signType" @on-change="radioChange">
                <Radio label="7">全选</Radio>
                <Radio v-if="addInfo.signType==2" label="2">博览会</Radio>
                <Radio v-else label="1">博览会</Radio>
                <Radio label="4">主题论坛</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="姓名" prop="name">
              <Input v-model="addInfo.name" placeholder="请输入姓名"></Input>
            </FormItem>
            <FormItem label="手机号" prop="mobile">
              <Input v-model="addInfo.mobile" placeholder="请输入手机号"></Input>
            </FormItem>
            <FormItem v-if="addInfo.signType==4 || addInfo.signType==7" label="报名论坛" prop="forumId">
              <select v-if="isIE" class="ie-select" multiple v-model="addInfo.forumId" placeholder="请选择报名的论坛">
                <option v-for="(item, index) in forumColumns" :key="index" :value="item.id" :disabled="item.id==1181850693314863105||item.id==1181851325673299969">{{item.title}} {{(item.id==1181850693314863105||item.id==1181851325673299969)? ' 已满':''}}</option>
              </select>
              <Select v-else v-model="addInfo.forumId" class="forum-select" multiple placeholder="请选择报名的论坛">
                <Option v-for="(item, index) in forumColumns" :key="index" :value="item.id" :disabled="item.id==1181850693314863105||item.id==1181851325673299969">{{item.title}} <span style="color: red">{{(item.id==1181850693314863105||item.id==1181851325673299969)? ' 已满':''}}</span></Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem v-if="addInfo.signType!=4&&addInfo.signType!=7" label="报名类型" prop="signType">
              <RadioGroup class="flex" v-model="addInfo.signType" @on-change="radioChange">
                <Radio label="1">普通观众</Radio>
                <Radio label="2">专业观众</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem v-if="addInfo.signType==2" label="单位名称" prop="companyName">
              <Input v-model="addInfo.companyName" placeholder="请输入单位全称"></Input>
            </FormItem>
            <FormItem v-if="addInfo.signType==4 || addInfo.signType==7" label="地区" prop="districtCn">
              <ACascader v-model="addInfo.districtCn" v-if="isIE" :data="cityList" @on-change="selectCity"></ACascader>
              <Cascader v-model="addInfo.districtCn" v-else :data="cityList" @on-change="selectCity"></Cascader>
            </FormItem>
            <FormItem v-if="addInfo.signType==4 || addInfo.signType==7" label="单位名称" prop="companyId">
              <Select v-model="addInfo.companyId" @on-query-change="queryChange" @on-change="companyChange" clearable filterable remote :remote-method="remoteSearch" :loading="remoteSearching" placeholder="请输入关键词搜索单位名称">
                <OptionGroup v-if="remoteSearchKey" label="使用搜索到的公司名">
                  <Option v-for="(option, index) in remoteSearchList" :key="index" :value="option.id" >{{option.name}}</Option>
                </OptionGroup>
                <OptionGroup v-if="remoteSearchKey" label="使用输入的公司名(需审核)">
                  <Option value="0">{{remoteSearchKey}}</Option>
                </OptionGroup>                
              </Select>
            </FormItem>
            <FormItem label="验证码" prop="msgCode">
              <Col span="16"><Input v-model="addInfo.msgCode" placeholder="请输入验证码"></Input></Col>
              <Col span="8"><Button :disabled="smsDisable" :loading="sendingCode" @click="getCode" class="fr e" type="primary">{{smsDisable? smsTime:sendingCode? '':'获取验证码'}}</Button></Col>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div class="tc read"><Checkbox v-model="checked"> 我已阅读</Checkbox> <a @click="showDialog=true">“参观须知”</a></div>
      <Button class="submit" type="primary" :loading="submitLoading" @click="submit()">提交</Button>
    </div> -->
    <Modal v-model="showDialog" class="activity-info-modal" title="参观须知" width="800">
      <div class="fl"><img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/assets/notice.png"></div>
      <div style="padding-left: 300px;font-size: 14px;line-height:24px;font-weight:400">
        一、出于公共安全的需要，组委会在展馆入口处设置了安全检查设备，请所有进馆人员不要随身携带大包、大件行李，并在进入展馆时接受展会安保人员的安全检查；<br>
        二、严禁携带易燃、易爆等危险物品及动物、易变质食品(如盒饭等)进入展馆，展馆内严禁吸烟和乱扔垃圾; <br>
        三、组委会对观众在展馆内的任何人身意外伤害不承担任何责任，由于展会现场人流密集，组委会强烈建议高龄长者以及身体不适者，请勿进馆参观；<br>
        四、带领儿童参观展会的观众，须在所有观展时间内对其儿童予以充分的注意和照顾，并对该儿童的行为和个人安全负责；<br>
        五、请参观时注意保管好您的随身物品，组委会对个人物品损失不承担任何赔偿责任；<br>
        六、未经展会组委会书面许可，任何机构或个人不得对观众、展商进行调查、民意测验、募捐，不得组织任何可能影响展会正常秩序和公众安全的群体活动；<br>
        七、如遇紧急事件，请各位观众听从工作人员和疏散引导员的指挥，有序撤离；<br>
        八、观众在进入展馆时应被视作已详细阅读本须知，并已同意和接受此须知的全部规定；<br>
        九、组委会及其工作人员有权拒绝违反本规定的观众入场或要求被认定违反此规定的观众退出展馆；<br>
        十、组委会保留对此须知最终解释权。感谢您的理解与支持,祝您观展愉快！ 
      </div>
      <div slot="footer"><Button type="primary" @click="showDialog=false">确定</Button></div>
    </Modal>
    <Modal v-model="showCode" class="activity-info-modal" title="微信扫码关注公众号" width="782" :closable="false">
      <img src="@/assets/wxcode.jpg">
      <div slot="footer"><Button type="primary" @click="showCode=false;$Message.success('报名成功，请留意入场二维码短信！')">确定</Button></div>
    </Modal>
  </div>
</div>
</template>
<script>
// import ACascader from '@/components/ACascader.vue'
export default {
  components: {
    // ACascader
  },
  data() {
    return {
      isIE: !!window.ActiveXObject || "ActiveXObject" in window,
      showDialog: false,
      showCode: false,
      uploading: false,
      submitLoading: false,
      checked: false,
      reset: true,
      addInfo: {
        signType: this.$route.params.type, // 报名类型
        name: '', // 联系人
        msgHash: '', // 验证码hash
        msgTime: '', // 验证码过期时间
        msgCode: '', // 验证码
        mobile: '', // 手机号
        district: '', // 地区
        districtCn: '', // 地区中文
        companyName: '', // 单位名称
        companyId: '', // 单位ID
        position: '', // 职务
        email: '', // 邮箱
        signFile: '', // 名片地址
        forumId: [], // 论坛ID
        forumCN: '', // 论坛名称
        exhibitionId: '', // 展馆ID
        exhibitionCN: '', // 展馆名称
        exhibitionArea: '', // 参展面积
        memo: '', // 媒体报名备注
        mediaType: 2, // 来源
      },
      // 0: 参展商
      // 1: 观众&&博览会&&普通观众
      // 2: 观众&&博览会&&专业观众
      // 3: 媒体
      // 4: 观众&&主题论坛
      // 5: 主旨论坛
      // 6: 平行论坛
      // 7: 观众&&全选
      ruleValidates: [
        '参展商占位',
        { // 普通观众
          signType: [{ required: true, message: '请选择报名类型', trigger: 'change' }],
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          mobile: [{ required: true, message: '请输手机号', trigger: 'blur' }],
          msgCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        },
        { // 专业观众
          signType: [{ required: true, message: '请选择报名类型', trigger: 'change' }],
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          mobile: [{ required: true, message: '请输手机号', trigger: 'blur' }],
          companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
          msgCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        },
        '媒体占位',
        { // 主题论坛
          signType: [{ required: true, message: '请选择报名类型', trigger: 'change' }],
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          mobile: [{ required: true, message: '请输手机号', trigger: 'blur' }],
          msgCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
          companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
          districtCn: [{ required: true, message: '请选择地区', trigger: 'change' }],
          forumId: [{ required: true,type:'array',message: '请选择论坛', trigger: 'change' }],
        },
        '主旨论坛',
        '平行论坛',
        { // 全选
          signType: [{ required: true, message: '请选择报名类型', trigger: 'change' }],
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          mobile: [{ required: true, message: '请输手机号', trigger: 'blur' }],
          msgCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
          companyId: [{ required: true, message: '请选择或输入公司名称', trigger: 'blur' }],
          districtCn: [{ required: true,message: '请选择地区', trigger: 'change' }],
          forumId: [{ required: true,type:'array',message: '请选择论坛', trigger: 'change' }],
        }
      ],
      cityList: [],
      forumColumns: [],
      libraryColumns: [],
      sendingCode: false,
      smsDisable: false,
      smsTime: 60,
      smsTimer: null,
      remoteSearching: false,
      remoteSearchList: [],
      remoteSearchKey: '',
    }
  },
  computed: {
    ruleValidate() {
      return this.ruleValidates[this.addInfo.signType]
    }
  },
  // created() {
  //   // 获取基础信息
  //   this.$http.get('/sys/syscity/all/list',{ // 城市基础信息
  //   }).then(res => {
  //     if (res.code === 0) {
  //       if (this.isIE) {
  //         this.cityList = res.data
  //       } else {
  //         this.cityList = res.data.map(province => {
  //           return {
  //             label: province.name,
  //             value: province.postCode,
  //             children: province.list.map(city => {
  //               return {
  //                 label: city.name,
  //                 value: city.postCode,
  //                 children: city.list.map(district => {
  //                   return {
  //                     label: district.name,
  //                     value: district.postCode
  //                   }
  //                 })
  //               }
  //             })
  //           }
  //         })
  //       }
  //     }
  //   })
  //   this.$http.get('/meeting/forum/list',{ // 论坛列表
  //   }).then(res => {
  //     if (res.code === 0) {
  //       this.forumColumns = res.data.slice(3) // 论坛列表去掉前三个开幕式
  //     }
  //   })
  //   this.$http.get('/meeting/showroom/list',{ // 展馆列表
  //   }).then(res => {
  //     if (res.code === 0) {
  //       this.libraryColumns = res.data
  //     }
  //   })
  // },
  methods: {
    queryChange(key) {
      this.remoteSearchKey = key
    },
    companyChange(id) {
      if (id == -1) {
        this.addInfo.companyName = this.remoteSearchKey
      }
    },
    // 获取验证码
    getCode() {
      if (!this.addInfo.mobile) {
        this.$Message.warning('请先输入手机号')
        return
      }
      if (!(/^((1[3-9][0-9])+\d{8})$/).test(this.addInfo.mobile)) {
        this.$Message.warning('请输入正确的手机号')
        return
      }
      this.sendingCode = true
      this.$http.get('/sys/sms/sendCheck',{
        params: {
          mobile: this.addInfo.mobile
        }
      }).then(res => {
        this.sendingCode = false
        if (res.code === 0) {
          this.addInfo.msgHash = res.data.msgHash
          this.addInfo.msgTime = res.data.msgTime
          this.$Message.success('短信已发送')
          this.smsDisable = true
          this.smsTimer = setInterval(() => {
            this.smsTime--
            if (this.smsTime === 0) {
              this.smsDisable = false
              clearInterval(this.smsTimer)
              this.smsTimer = null
              this.smsTime = 60
            }
          }, 1000);
        } else {
          this.$Message.warning(res.msg)
        }
      }).catch(err => {
        this.sendingCode = false
        this.$Message.warning(err.message)
      })
    },
    selectCity(a, e) {
      this.addInfo.district = e[2].value
      this.addInfo.districtCn = e[0].label + '-' + e[1].label + '-' + e[2].label
    },
    radioChange() {
      this.reset = false
      this.$nextTick(() => {
        this.reset = true
      })
    },
    remoteSearch(key) { // 公司远程搜索
      this.remoteSearching = true
      this.$http.post('/meeting/company/listByCode', {
        postCode: this.addInfo.district,
        name: key
      }).then(res => {
        this.remoteSearching = false
        if (res.code === 0) {
          this.remoteSearchList = res.data.filter(item => item.auditStatus)
        }
      })
    },
    submit() {
      this.$refs.signForm.validate((valid) => {
        if (valid) {
          if (!this.checked) {
            alert('请阅读并同意协议！')
            return
          }
          this.submitLoading = true
          this.$http.post('/meeting/itiesign', this.addInfo).then(res => {
            this.submitLoading = false
            if (res.code === 0) {
              this.showCode = true
              this.$refs.signForm.resetFields();
            } else {
              this.$Message.warning(res.msg)
            }
          }).catch(err => {
            this.submitLoading = false
            this.$Message.warning(err.message)
          })
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.sign-outer {
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
}
.sign {
  padding-bottom: 80px;
  min-height: calc(100vh - 470px);
  .form-wrap {
    background: #FFF;
    border: 1px solid #E5E5E5;
    border-top: 4px solid #4A90E2;
    padding: 30px 20px;
  }
  .upload {
    justify-content: flex-start;
    .tr {
      margin-right: 10px;
      width: 110px;
      color: #333;
      font-size: 16px;
      font-weight: 400px;
    }
    .file-name {
      font-size: 14px;
      line-height: 30px;
      color: #333;
      padding: 0 20px;
    }
  }
  .read {
    font-size: 14px;
    .ivu-checkbox-wrapper {
      font-size: 14px;
      padding: 10px 0;
      line-height: 30px;
      color: #333;
    }
    a {
      color: #4A90E2;
      text-decoration: underline;
    }
  }
}
.forum-select {
  overflow: hidden;
  .ivu-select-item-selected::after {
    line-height: 40px;
    font-size: 40px;
  }
}
</style>
<style lang="less">
.sign {
  input::-webkit-input-placeholder, .ivu-select-placeholder {
    color: #999 !important;
  }
  .form-wrap {
    .ivu-form-item {
      padding: 0 20px;
      .ivu-form-item-label {
        font-size: 16px;
        line-height: 35px;
        font-weight: 400;
        color: #333;
      }
      .ivu-form-item-content {
        height: 55px;
        .ivu-icon-ios-arrow-down {
          font-size: 20px;
          color: #4A90E2;
        }
        .ivu-radio-group {
          padding: 0 50px;
          justify-content: space-around;
          .ivu-radio-wrapper {
            font-size: 16px;
            color: #333;
            .ivu-radio {
              margin-right: 10px;
              .ivu-radio-inner {
                background: rgba(0, 0, 0, 0);
              }
            }
            .ivu-radio-checked {
              .ivu-radio-inner {
                border-color: #4A90E2;
              }
              .ivu-radio-inner:after {
                background-color: #4A90E2;
              }
              color: #333;
            }
          }
        }
        .ivu-input-wrapper, .ivu-select, .ivu-radio-group, .ivu-cascader {
          height: 55px;
          border-radius: 4px;
          background: #FFF;
          border: 1px solid #E6E6E6;
          .ivu-input, .ivu-select-selection, .ivu-select-placeholder, .ivu-select-selected-value {
            background: none;
            border: none;
            height: 45px;
            margin: 5px 0;
            padding: 0 10px;
            font-size: 16px;
            color: #333;
            line-height: 45px;
          }
          .ivu-select-input {
            font-size: 16px;
            color: #333;
            line-height: 45px;
          }
          .ivu-cascader-menu {
            border-right: 1px solid #CCC;
          }
          .ivu-cascader-menu-item {
            font-size: 16px !important;
            line-height: 30px;
            color: #333 !important;
          }
          .ivu-cascader-menu-item:hover, .ivu-cascader-menu-item-active {
            color: #2b85e4;
            background: #cde5ff;
          }
          .ivu-select-dropdown {
            color: #2b85e4;
            background: #F5FAFF;
            .ivu-select-item {
              font-size: 16px !important;
              line-height: 30px;
              color: #333 !important;
            }
            .ivu-select-item:hover, .ivu-select-item-focus {
              background: #cde5ff;
              color: #333;
            }
          }
          .ivu-input:focus {
            box-shadow: none;
          }
        }
      }
      .ivu-btn {
        height: 49px;
        margin: 3px 0;
        width: 110px;
        font-size: 16px;
        background: #4A90E2;
        border: none;
      }
      .ivu-btn:disabled {
        border: none;
        background: #999;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .submit {
    display: block;
    margin: 0px auto !important;
    height: 50px;
    font-weight: 500;
    width: 200px;
    font-size: 16px;
    background: #4A90E2;
    border: none;
  }
  .upload-btn {
    background: #DCECFF;
    color: #125FAD;
    border: 1px solid #4489FC;
    height: 35px;
    .ivu-icon-ios-cloud-upload-outline {
      font-size: 17px !important;
      font-weight: bolder;
    }
  }
}
.activity-info-modal {
  .ivu-modal-close {
    .ivu-icon-ios-close {
      color: #FFF;
    }
  }
  .ivu-modal-header {
    background: linear-gradient(270deg,rgba(43,95,254,1) 0%,rgba(68,137,252,1) 100%);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .ivu-modal-header-inner {
      color: #FFF;
      font-size: 20px;
      font-weight: normal;
    }
  }
  .ivu-modal-body {
    .fl {
      img {
        width: 274px;
        height: auto;
      }
    }
  }
}
</style>
